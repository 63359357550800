<template>
    <div></div>
</template>

<script>
    import {getTimeCost, parseTime, newDownload} from '@/utils/index.js'
    import {getQrCodeUrl, getAudioToken} from "@/api/common";
    import {log} from "@/utils/LogUtil";
    import {notification} from "@/utils/NoticeUtil";
    import FileBusinessEnum from "@/enums/FileBusinessEnum";
    import TestItemEnum from "@/views/testMaterialQuestion/enums/TestItemEnum";
    import { pushToPrinter } from "@/api/printServer/printServer";
    import {downloadIO} from "@/api/downloadIO.js";

    export default {
        name: 'AbsComponent',
        data() {
            return {
                title: '【点击打开】木瓜贴片',
                query: {},
                pageWidth: null,
                screenWidth: document.body.clientWidth,//初始化宽度
                screenHeight: document.body.clientHeight,//初始化宽度
            }
        },
        created() {
            /*for (let key in this.$route.query) this.query[key] = this.$route.query[key]*/
            this.query = this.$route.query
        },
        mounted() {
            var _this = this
            window.onresize = () => {
                return (() => {
                    _this .screenWidth = document.body.clientWidth
                    _this .screenHeight = document.body.clientHeight
                })()
            }
        },
        methods: {
            getClientName(clientName) {
                clientName = clientName.replaceAll("深圳市", "")
                clientName = clientName.replaceAll("(深圳)", "")
                clientName = clientName.replaceAll("（深圳）", "")
                clientName = clientName.replaceAll("深圳", "")
                clientName = clientName.replaceAll("科技有限公司", "")
                clientName = clientName.replaceAll("技术有限公司", "")
                clientName = clientName.replaceAll("股份有限公司", "")
                clientName = clientName.replaceAll("有限责任公司", "")
                clientName = clientName.replaceAll("工程有限公司", "")
                clientName = clientName.replaceAll("有限公司", "")
                if (clientName)
                    return clientName.length <= 8 ? clientName : (clientName.substring(0, 7) + '...')
            },
            /*
             * 功能：获取图片URL
             * Author：潘溢林
             */
            getImageUrl({image, isFull = true, isDelimiter = true}) {
                if (!image) return ''
                return this.convertImagePath({ path: image.path, isFull, isDelimiter })
            },
            /*
             * 功能：获取图片URL
             * Author：潘溢林
             */
            getImagesUrl({images, isFull = true, isDelimiter = true}) {
                if (this.isEmptyList(images)) return []
                return images.map(image => {
                    return this.convertImagePath({ path: image.path, isFull, isDelimiter })
                })
            },
            /*
             * 功能： 获取图片URL
             * Author：潘溢林
             */
            convertImagePath({path, isFull = true, isDelimiter = true}) {
                let isFullUrl = isFull ? process.env.VUE_APP_IMAGE_URL : ''
                let isHaveDelimiter = isDelimiter ? '/' : ''
                return isFullUrl + isHaveDelimiter + path
            },
            /*
             * 功能： 格式化时间
             * Author：潘溢林
             */
            formatterTime(time, option) {
                return parseTime(time, option)
            },
            /*
             * 功能： 格式化时间
             * Author：潘溢林
             */
            getTimeCost(startTime, endTime) {
                return getTimeCost(startTime, endTime)
            },
            /*
             * 功能：获取指定的formItem
             * Author：潘溢林
             */
            getFormItem(form, label) {
                let formItems = form.formItems
                for (let formItem of formItems) if (formItem.label === label) return formItem
                return null
            },
            /*
             * 功能：获取指定的formItem
             * Author：潘溢林
             */
            getSearchItem(inputs, label) {
                if (!inputs) return null
                for (let searcher of inputs) if (searcher.label === label) return searcher
                return null
            },
            /*
             * 功能：获取指定的formItem
             * Author：潘溢林
             */
            downloadFile(file, show=true) {
                return newDownload(file, show)
            },
            /*
             * 功能：开始加载
             * Author：潘溢林
             */
            loadingStart({ message='加载数据中...', spinner, background  }) {
                this.$store.dispatch('loading', {
                    loading: true,
                    message,
                    spinner,
                    background,
                })
            },
            /*
             * 功能：结束加载
             * Author：潘溢林
             */
            loadingEnd() {
                this.$store.dispatch('loading', {
                    loading: false,
                })
            },
            /*
             * 功能：更新标题
             * Author：潘溢林
             */
            updateTitle() {
                document.title = this.title
            },
            /*
             * 功能：打开弹窗
             * Author：潘溢林
             */
            openDialog({ title, message, messageAlign='center', cancelButtonText, confirmButtonText, isAlter=false }) {
                /* 仅提示弹窗 */
                if (isAlter) return this.$dialog.alert({
                    message,
                    messageAlign,
                    allowHtml: true
                }).then(() => {});
                /* 返回取消或确认弹窗 */
                return this.$dialog.confirm({
                    title,
                    message,
                    messageAlign,
                    confirmButtonText,
                    cancelButtonText,
                    allowHtml: true
                });
            },
            /*
             * 功能：打开弹窗
             * Author：潘溢林
             */
            openDialogV2({ title, message, type='warning', messageAlign, isAlter=false, confirmButtonText, cancelButtonText }) {
                /* 仅提示弹窗 */
                if (isAlter) return this.$alert(message, title,{
                    type,
                    center: messageAlign,
                    dangerouslyUseHTMLString: true
                }).then(() => {});
                /* 返回取消或确认弹窗 */
                return this.$confirm(message, title, {
                    type,
                    center: messageAlign,
                    dangerouslyUseHTMLString: true,
                    confirmButtonText,
                    cancelButtonText
                })
            },
            /*
             * 功能：是否空list
             * Author：潘溢林
             */
            isEmptyList(list) {
                return !list || list.length <= 0
            },
            /*
             * 功能：获取移动端地址
             * Author：潘溢林
             */
            getMobileAddrV2(data) {
                return getQrCodeUrl(data).then(response => {
                    return response
                }).catch(error => error)
            },
            /*
             * 功能：文件业务类型
             * Author：潘溢林
             */
            FileBusinessEnum() {
                return FileBusinessEnum
            },
            /*
             * 功能：文件业务类型
             * Author：潘溢林
             */
            TestItemEnum() {
                return TestItemEnum
            },
            /*
             * 功能：获取进度条颜色
             * Author：潘溢林
             */
            getProgressColor(percentage) {
                if (percentage === 100) return '#67C23A'
                if (percentage > 100) return '#F56C6C'
                return '#409EFF'
            },
            /*
             * 功能：打开URI
             * Author：潘溢林
             */
            openUri(uri, includePrefixPath=false, isOpenNewWindow=true) {
                if (includePrefixPath) uri = this.getUri(uri)

                if (isOpenNewWindow) return window.open(uri)
                else window.location = uri
            },
            /*
             * 功能：打开URI
             * Author：潘溢林
             */
            getUri(uri) {
                return location.protocol + '//' + location.hostname + ':' + location.port + '/#/' + uri
            },
            /*
             * 功能：输出日志
             * Author：潘溢林
             */
            log(message) {
                log(message)
            },
            /*
             * 功能：是否是微信游览器
             * Author：潘溢林
             */
            isWeChatBrowser() {
                return this.$store.getters.isWeChatBrowser
            },
            /*
             * 功能：返回图片
             * Author：潘溢林
             */
            getFiles(files, fileType) {
                if (this.isEmptyList(files)) return []
                if (!fileType) return notification({ message: '请指定FileType' })
                return files.map(item => {
                    return {
                        id: item.id,
                        businessType: fileType
                    }
                })
            },
            /*
             * 功能：播报声音
             * Author：潘溢林
             */
            broadcastVideo({ text='', rate=1, volume=10 }) {
                if (!text) return
                let msg = new SpeechSynthesisUtterance();
                msg.rate = rate
                msg.pitch = 10
                msg.text = text
                msg.volume = volume
                window.speechSynthesis.speak(msg);

                // return getAudioToken({}).then(res => {
                //     this.$store.commit('SET_AUDIO', {
                //         src: 'https://tsn.baidu.com/text2audio?tex=' + text +
                //             '&tok=' + res +
                //             '&cuid=' + '11784370' +
                //             '&ctp=' + 1 +
                //             '&lan=' + 'zh' +
                //             '&spd=' + 3 +
                //             '&pit=' + 5 +
                //             '&vol=' + 15 +
                //             '&aue=' + 3
                //     })
                // })
            },
            /*
             * 功能：发送请求
             * Author：潘溢林
             */
            printPdfByLocal({ width, height, orientation, printerName, printFileName }) {
                return pushToPrinter(
                    {
                        equipment: { ip: '127.0.0.1', port: '12501' },
                        content: printFileName,
                        options: {
                            height,
                            orientation,
                            printFileName,
                            printerName,
                            width,
                        }
                    }
                )
            },
            /*
             * 功能：发送请求
             * Author：潘溢林
             */
            getFileIds(bFiles) {
                if (this.isEmptyList(bFiles)) return []
                return bFiles.map(item => parseInt(item.id || item.pkFileId))
            },
            /**
             * 以流方式进行下载
             * @param {*} url  请求微服务URL
             */
            downloadFileByte(url) {
                downloadIO(url);
            },
            getTalksOfferPrice(businessAccountingDo) {
                var sumPrice = Number(businessAccountingDo.sumTalksOfferPrice || businessAccountingDo.talksOfferPrice)
                return sumPrice
            }
        }
    }
</script>
