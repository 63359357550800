import Vue from 'vue'
import {log} from '../utils/LogUtil'
import {getCurWorkPath} from "@/utils/CookieUtil";
import VueRouter from 'vue-router'
import store from '../store'
import ComponentEnum from "@/router/ComponentEnum";

Vue.use(VueRouter)

/* 历史版本的路由信息 */
const routers = [

    /* 按新版进行开发的路径 */
    /*{ path: '/', name: 'index', component: index },*/

    /* 登录页面 */
    { path: '/login', name: 'login', component: () => import( "@/views/login/LoginView") },
    { path: '/person', name: 'person', component: () => import( "@/layout/MainLayout"), redirect: '/person/info', children: [
        { path: '/person/info', name: 'personInfo', component: () => import("@/views/human/PersonalPage") }
    ]},

    {
        path: '/testMaterial',
        name: 'TestMaterialQuestionView',
        component: () => import( "@/views/testMaterial/TestMaterialQuestionView")
    },
    {
        path: '/testMaterialQuestion',
        name: 'TestMaterialQuestionView',
        component: () => import( "@/views/testMaterialQuestion/TestMaterialQuestion"),
        meta: {isMustLogin: false},
    },
    {path: '/FeederMaterial', name: 'FeederMaterial', component: () => import("@/views/ActionBusiness/feederMaterial/FeederMaterialList"), meta: {isMustLogin: false}},
    {path: '/BakeMaterial', name: 'BakeMaterial', component: () => import("@/views/ActionBusiness/bakeMaterial/BakeMaterialList"), meta: {isMustLogin: false}},
    {path: '/ExceptionList', name: 'ExceptionList', component: () => import("@/views/ActionBusiness/exceptionList/ExceptionList"), meta: {isMustLogin: false}},
    {path: '/WorkFlowView', name: 'WorkFlowView', component: () => import("@/views/workflow/WorkFlowView"), meta: {isMustLogin: false}},
    {path: '/ocm', name: 'OrderCheckMaterial', component: () => import('@/views/factory/commonView/orderCenter/orderCheckMaterial/OrderCheckMaterial')},
    {path: '/opc', name: 'OutPutCoordinate', component: () => import('@/views/factory/commonView/gerber/OutPutCoordinate')},
    {path: '/ocv', name: 'OrderCraftView', component: () => import('@/views/factory/commonView/OrderCraft/OrderCraftView'), meta: {isMustLogin: true}},
    {path: '/ocv2', name: 'OrderCraftViewV2', component: () => import('@/views/factory/commonView/OrderCraftV2/OrderCraftViewV2'), meta: {isMustLogin: true}},
    {path: '/og', name: 'OrderGerber', component: () => import('@/views/factory/commonView/OrderCraft/OrderGerber'), meta: {isMustLogin: true}},
    {path: '/dmp', name: 'DebugMachineProgramV2', component: () => import('@/views/factory/commonView/orderCenter/orderStepComponents/smtMaterialPaste/DebugMachineProgramV2.vue'), meta: {isMustLogin: true}},
    {path: '/bdcoav', name: 'BomDraftCommitOrderApplyView', component: () => import("@/views/factory/sale/order/reviseDialog/BomDraftCommitOrderApplyView"), meta: {isMustLogin: false}},
    
    /* 二维码页面，无需登录 */

    {path: '/clientAccountStatementView', name: 'clientAccountStatementView', component: () => import("@/views/factory/sale/order/task/clientAccountStatementView"), meta: {isMustLogin: false}},
    {path: '/newAccountReceivableView', name: 'newAccountReceivableView', component: () => import("@/views/factory/sale/AccountReceivable/newAccountReceivableView"), meta: {isMustLogin: false}},
    {path: '/qrCode', name: 'qrCode', component: () => import("@/views/factory/commonView/qrCode/qrCode"), meta: {isMustLogin: false}},
    {path: '/OrderInfoOffline', name: 'OrderInfoOffline', component: () => import("@/views/factory/commonView/orderCenter/OrderInfo"), meta: {isMustLogin: true}},
    {path: '/sccv', name: 'SendPackSetClientCheckView', component: () => import("@/views/factory/ShippingClerk/ShippingCenter/SendPackSetClientCheckView"), meta: {isMustLogin: false}},
    {path: '/si', name: 'SendPackItemCheckView', component: () => import("@/views/factory/ShippingClerk/ShippingCenter/SendPackItemCheckView"), meta: {isMustLogin: false}},
    {path: '/pda', name: 'PdaView', component: () => import("@/views/factory/commonView/pda/PdaView")},
    {path: '/psv', name: 'ProductionSupplementView', component: () => import("@/views/factory/factoryDirector/ProductionSupplement/ProductionSupplementView"), meta: {isMustLogin: false}},
    {path: '/bltComputerMonitor', name: 'BltComputerMonitor', component: () => import("@/views/factory/factoryDirector/BltComputerMonitor/BltComputerMonitorView"), meta: {isMustLogin: true}},
    {path: '/pmi', name: 'PrepareMaterialIndex', component: () => import("@/views/factory/commonView/OrderCraft/smartProgram/PrepareMaterialIndex"), meta: {isMustLogin: false}},
    {path: '/ocmpfv', name: 'OrderCheckMaterialPreFormView', component: () => import("@/views/factory/commonView/orderCenter/orderCheckMaterial/OrderCheckMaterialPreFormView"), meta: {isMustLogin: true}},
    {path: '/issnfm', name: 'inStoreSteelNetFixtureMobile', component: () => import("@/views/factory/Engineer/mobile/inStoreSteelNetFixtureMobile"), meta: {isMustLogin: false}},
    {path: '/dnfm', name: 'destoryNetFixtureMobile', component: () => import("@/views/factory/Engineer/mobile/destoryNetFixtureMobile"), meta: {isMustLogin: false}},
    {path: '/snfmm', name: 'SteelNetFixtureMobileMain', component: () => import("@/views/factory/Engineer/mobile/SteelNetFixtureMobileMain"), meta: {isMustLogin: false}},
    {path: '/sisnfm', name: 'shelfInventoryMobile', component: () => import("@/views/factory/Engineer/mobile/shelfInventoryMobile"), meta: {isMustLogin: false}},
    {path: '/oed', name: 'OrderExtendsDialog', component: () => import("@/views/factory/commonView/orderCenter/OrderExtendsDialog.vue"), meta: {isMustLogin: false}},
    {path: '/assnfm', name: 'accompanyingStorageSteelNetFixtureMobile', component: () => import("@/views/factory/Engineer/mobile/accompanyingStorageSteelNetFixtureMobile.vue"), meta: {isMustLogin: false}},
    {path: '/assqm', name: 'accompanySearchMobile', component: () => import("@/views/factory/Engineer/mobile/accompanySearchMobile.vue"), meta: {isMustLogin: false}},
    {path: '/sbmm', name: 'ShippingBoardMobileMain', component: () => import("@/views/factory/ShippingClerk/ShippingDashboard/mobile/ShippingBoardMobileMain"), meta: {isMustLogin: true}},
    {path: '/pism', name: 'ProductsInStoreMobile', component: () => import("@/views/factory/ShippingClerk/ShippingDashboard/mobile/ProductsInStoreMobile"), meta: {isMustLogin: true}},
    {path: '/ppm', name: 'ProductsPackingMobile', component: () => import("@/views/factory/ShippingClerk/ShippingDashboard/mobile/ProductsPackingMobile"), meta: {isMustLogin: true}},
    {path: '/pim', name: 'PackingImageMobile', component: () => import("@/views/factory/ShippingClerk/ShippingDashboard/mobile/PackingImageMobile"), meta: {isMustLogin: false}},
    {path: '/pcim', name: 'PcbaCheckImageMobile', component: () => import("@/views/factory/ShippingClerk/ShippingDashboard/mobile/PcbaCheckImageMobile"), meta: {isMustLogin: true}},
   //手机端上传炉温曲线
    {path: '/sfabi', name: 'mobileUpateImage', component: () => import("@/views/factory/commonView/FlowPathConfig/components/actionTimeline/mobileUpateImage.vue"), meta: {isMustLogin: false}},
   
    /* 客户页面 */
    {path: '/cmllv', name: 'ClientMaterialLessListView', component: () => import("@/views/client/exceptionView/clientMaterialLessList/ClientMaterialLessListView"), meta: {isMustLogin: false}},
    {path: '/emv', name: 'EscrowMaterialView', component: () => import("@/views/client/escrow/EscrowMaterialView"), meta: {isMustLogin: false}},
    {path: '/crm', name: 'ClientRedundantMaterialView', component: () => import("@/views/client/redundantMaterial/ClientRedundantMaterialView"), meta: {isMustLogin: false}},
    {path: '/ssi', name: 'InterceptShareLinkComponent', component: () => import("@/views/factory/ShippingClerk/ShippingCenter/interceptComponent/InterceptShareLinkComponent"), meta: {isMustLogin: false}},
    {path: '/ssic', name: 'InterceptShareLinkContainer', component: () => import("@/views/factory/ShippingClerk/ShippingCenter/interceptComponent/InterceptShareLinkContainer"), meta: {isMustLogin: false}},
    {path: '/otv', name: 'OrderTraceView', component: () => import("@/views/factory/commonView/orderCenter/OrderTraceView"), meta: {isMustLogin: true}},
    {path: '/pmc', name: 'pluginMaterialCheck', component: () => import("@/views/client/exceptionView/pluginMaterialCheck/pluginMaterialCheck"), meta: {isMustLogin: false}},
    {path: '/oqr', name: 'OrderQualityReport', component: () => import("@/views/factory/commonView/orderCenter/orderQuanlityReport/OrderQualityReportComponent"), meta: {isMustLogin: false}},
    {path: '/opm', name: 'OrderProcessMobile', component: () => import("@/views/factory/commonView/orderCenter/OrderProcessMobile"), meta: {isMustLogin: false}},
	
    //2023.03.03 首件检测报告
	{path: '/fr', name: 'FaiReport', component: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/smtFirst/components/FaiReport"), meta: {isMustLogin: false}},
    // 2024.03.23 待烘烤列表
    {path: '/mwb', name: 'mobileWaitingBake', component: () => import("@/views/factory/commonView/FlowPathConfig/components/sop/BAKE/mobileWaitingBake"), meta: {isMustLogin: false}},
    {path: '/mfb', name: 'mobileFinishedBake', component: () => import("@/views/factory/commonView/FlowPathConfig/components/sop/BAKE/mobileFinishedBake"), meta: {isMustLogin: false}},
   
    //2023.12.20 排产计划
    {path: '/prodsc', name: 'productionScheduleCenter', component: () => import("@/views/factory/commonView/orderCenter/productionSchedule/productionScheduleCenter"), meta: {isMustLogin: false}},
    
    //2023.11.07 锡膏管控手机端 
    {path: '/mspfridge', name: 'SolderPasteFridge', component: () => import("@/views/factory/commonView/orderCenter/orderStepComponents/solderPrint/mobile/SolderPasteFridgeMobile.vue"), meta: {isMustLogin: false}},
   
    //2024.03.28 排产
    {path: '/PSC', name: 'ProductionScheduling', component: () => import("@/views/factory/commonView/ProductionScheduling/index.vue"), meta: {isMustLogin: false}},
   
	/* 供应商页面 */
    {path: '/sov', name: 'SupplierOrderView', component: () => import("@/views/supplier/SupplierOrderCenter/SupplierOrderView"), meta: {isMustLogin: true}},
    {path: '/sosbv', name: 'SupplierOrderSendBillView', component: () => import("@/views/supplier/SupplierOrderCenter/SupplierOrderSendBillView"), meta: {isMustLogin: true}},
    
    /* 流程通用配置组件 */
    {path: '/fpc', name: 'FlowPathConfigIndex', component: () => import("@/views/factory/commonView/FlowPathConfig"), meta: {isMustLogin: false}},
    {path: '/fpcc', name: 'FlowPathConfig', component: () => import("@/views/factory/commonView/FlowPathConfig/config.vue"), meta: {isMustLogin: false}},

    /**2024.01.25  登记打包数据*/
    {path: '/mcip', name: 'mobileCheckInPacking', component: () => import("@/views/factory/commonView/FlowPathConfig/components/sop/FPS/mobileCheckInPacking.vue"), meta: {isMustLogin: false}},
    /**2024.03.11 SOP工艺要求凭证上传*/
    {path: '/mpr', name: 'mobileCheckRequire', component: () => import("@/views/factory/commonView/FlowPathConfig/components/sop/SI/mobileCheckRequire.vue"), meta: {isMustLogin: false}},
    {path: '/scsio', name: 'mobileSalesCheckRequire', component: () => import("@/views/factory/commonView/FlowPathConfig/components/sop/SI/mobileSalesCheckRequire.vue"), meta: {isMustLogin: false}},

    /**2024.03.11 出货记录*/
    {path: '/mobr', name: 'mobileShipmentRecord', component: () => import("@/views/factory/commonView/FlowPathConfig/components/sop/FPS/mobileShipmentRecord.vue"), meta: {isMustLogin: false}},
   
     /** 2024.05.27 退客清单*/
    {path: '/mrcm', name: 'returnClientMat', component: () => import("@/views/factory/commonView/FlowPathConfig/components/sop/CLM/returnClientMat.vue"), meta: {isMustLogin: false}},
    
     /** 2024.05.29 出货样品核对*/
    {path: '/mfpscs', name: 'mobileVoucherSamplePlate', component: () => import("@/views/factory/commonView/FlowPathConfig/components/sop/FPS/mobileVoucherSamplePlate.vue"), meta: {isMustLogin: false}},
   
    // 三合一页面
    {path: '/bcgm', name: 'BomCoordinateGerberMerge', component: () => import("@/views/project/bomCoordinateGerberMerge/BomCoordinateGerberMerge.vue")},

    /* 错误页配置 */
    {path: '*', redirect: '/404', hidden: true },
    {path: '/404', hidden: true, component: () => import("@/views/errorPage/Error_404")},
    {path: '/Offer', name: 'Offer', component: () => import("@/views/factory/sale/order/OfferView"), meta: {isMustLogin: false}},
    {path: '/ConfOffer', name: 'ConfOffer', component: () => import("@/views/factory/sale/order/ConfCofferView"), meta: {isMustLogin: false}},

]

/* 获取当前角色的工作路径 */
let curWorkPath = getCurWorkPathAndUpdateComponent()

/* 更新工作路径所使用的组件 */
routers.push(...curWorkPath)

const router = new VueRouter({
    routes: routers
})

router.beforeEach((to, from, next) => {
    /* 如果使用微信游览器打开，每次URI变更，重新注册一次Config */
    if (store.getters.isWeChatBrowser) {
        log('微信游览器：为该网页注册Config：', to)
        /*registerConfig()*/
    }

    /* 获取Token */
    let token = store.getters.token
    log('当前登录Token：', token)

    /* 获取员工登录信息 */
    let humanInfo = store.getters.humanInfo
    log('当前登录员工：', humanInfo || '无登录信息')

    if ((to.meta && to.meta.isMustLogin !== false) && !humanInfo && token) {
        store.dispatch('updateHumanInfo')
    }

    nextPath(token, to, next)
})

router.onError(error => {
    console.error('Router Error：',error)
})

/*
 * 功能：跳转路径
 * Author：潘溢林
 */
function nextPath(token, to, next) {
    /* 如果页面不需要登录，则直接跳转 */
    if (to.meta && to.meta.isMustLogin === false) {
        return next()
    }

    /* 未登录，直接跳转到登录页 */
    if (!token && to.name !== 'login') {
        log('未登录，跳转到登录页')
        store.commit('SET_CUR_PATH', 'login')
        return next('login')
    }

    /* 如果已经登录，并且还访问登录页，直接返回主页 */
    if (token && to.name === 'login') {
        log('已登录，不允许访问登录页，跳转到主页')
        store.commit('SET_CUR_PATH', '/')
        return next('/')
    }

    store.commit('SET_CUR_PATH', to.path)

    // 如果URI带参数，则需进行解析
    next()
}

export default router

/*
 * 功能：获取当前角色的工作路径
 * Author：潘溢林
 */
function getCurWorkPathAndUpdateComponent() {
    let curWorkPath = getCurWorkPath()

    if (!curWorkPath) return []

    for (let workPath of curWorkPath) resolveComponent(workPath)

    return curWorkPath
}

/*
 * 功能：解析组件
 * Author：潘溢林
 */
function resolveComponent(path) {
    if (path.component) path.component = ComponentEnum[path.component]

    if (path.children && path.children.length) {
        for (let child of path.children) {
            resolveComponent(child)
        }
    }
}