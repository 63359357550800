<template>
    <el-header class="HeaderContainer" style="padding: 0;" height="60px">
        <!-- 电脑端展示 -->
        <div class="HeaderToolsBox hidden-sm-and-down">
            <!-- 企业LOGO -->
            <div class="HeaderImgBox">
                <img :src="logo" alt="企业LOGO" width="auto" height="40px">
            </div>
            <!-- 菜单 -->
            <div class="HeaderMenuBox">
                <menu-container></menu-container>
            </div>
        </div>
        <!-- 移动端展示 -->
        <div class="hidden-sm-and-up">
            <!--<div class="HeaderMiniImgBox">
                <img :src="muguaLogo" alt="企业LOGO" width="auto" height="50px">
            </div>-->
            <menu-drawer-container />
        </div>

        <div style="position: fixed;right: 0px;top:0px;z-index: 10;">
            <!-- 个人信息展示 -->
            <div class="humanInfoBox">
                <el-dropdown v-if="humanInfo" placement="top">
                    <div class="humanInfoHeader highLight">
                        <span style=" margin-right: 15px;">{{ humanInfo.humanName }} </span>

                        <van-image v-if="humanInfo.headerImage" width="30px" height="auto" fit="contain" round
                                   :src="convertImagePath({ path: humanInfo.headerImage })" />
                        <van-image v-else-if="humanInfo.sex === '男'" width="30px" height="auto" fit="contain" round
                                   :src="man" />
                        <van-image v-else="humanInfo.sex === '女'" width="30px" height="auto" fit="contain" round :src="woman" />
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <div v-for="(action, actionIndex) of actions" :key="actionIndex">
                            <el-dropdown-item v-if="action.show !== false" @click.native="action.click">
                                <div :class="action.className">
                                    <i :class="action.icon"></i>
                                    <span>{{ action.text }}</span>
                                </div>
                            </el-dropdown-item>
                        </div>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- 角色信息 -->
            <div v-if="humanIdentities && humanIdentities.length == 1" class="humanIdentityBox normalBtn">
                <div style="margin-top: 18px;">当前角色:{{curIdentity.identityName}}</div>
            </div>
            <div v-if="humanIdentities && humanIdentities.length > 1" class="humanIdentityBox normalBtn">
                <el-dropdown v-if="curIdentity" placement="top">
                    <div class="curIdentityBox highLight">
                    <span>
                        <span class="hidden-sm-and-down">当前角色：</span>
                        {{ curIdentity.identityName }}
                        &nbsp;
                    </span>
                        <i class="el-icon-arrow-down"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(identity, identityIndex) of humanIdentities" :key="identityIndex"
                                          @click.native="toggleRole(identity)">
                        <span style="display: inline-block; width: 20px;">
                            <i v-if="identity.identityName === curIdentity.identityName" class="el-icon-right" />
                        </span>
                            <span>{{ identity.identityName }}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- 消息提示 -->
            <div class="messageNoticeBox" @click="openMessageNoticeDialog">
                <el-badge :value="messageTotal" :hidden="!messageTotal" class="messageNoticeBoxItem">
                    <span class="el-icon-message-solid"></span>
                </el-badge>
            </div>
        </div>

        <factory-material-searcher ref="FactoryMaterialSearcher" />
        <MessageNoticeDialog ref="MessageNoticeDialog" />
        <BindOperatorDialog ref="BindOperatorDialog" />
        <stationConfigDialog ref="stationConfigDialogRef" />
    </el-header>
</template>

<script>
import { mapGetters } from 'vuex'
import man from '@/images/man.jpeg'
import woman from '@/images/woman.jpeg'
import logo from '@/images/logo.png'
import mugualogo from '@/images/mugualogo.png'
import AbsComponent from "@/components/genericComponents/AbsComponent";
import MenuContainer from "@/layout/menuContainer/MenuContainer";
import MenuDrawerContainer from "@/layout/menuContainer/MenuDrawerContainer";
import FactoryMaterialSearcher from "@/components/businessComponent/FactoryMaterialSearcher";
import MessageNoticeDialog from '@/components/businessComponent/MessageNoticeDialog.vue'
import BindOperatorDialog from '@/components/businessComponent/BindOperatorDialog'
import stationConfigDialog from '@/components/businessComponent/stationConfigDialog'


import CookieEnum from "@/enums/CookieEnum";
import { MessageNoticeCount, PhysicalOperatorKeepAlive } from '@/api/commonApi'
import { errorNotification, errorNotice } from "@/utils/NoticeUtil";
import { getCookie } from "@/utils/CookieUtil";

import { getUserInfoApi } from "@/api/authCenter"

export default {
    name: "HeaderContainer",
    components: {
        MenuDrawerContainer, MenuContainer, FactoryMaterialSearcher, MessageNoticeDialog, BindOperatorDialog,
        stationConfigDialog
    },
    extends: AbsComponent,
    computed: {
        ...mapGetters([
            'humanInfo',
            'curIdentity',
            'humanIdentities',
        ])
    },
    data() {
        return {
            loginHumanCode: '',
            man: man,
            woman: woman,
            logo: logo,
            muguaLogo: mugualogo,
            showPopover: false,
            messageTotal: 0,
            actions: [
                { text: '个人主页', icon: 'el-icon-user-solid', click: this.toPersonalPage, show: true },
                /*{ text: '分享本页', icon: 'el-icon-s-promotion', click: this.SharedPage },
                { text: '扫一扫', icon: 'el-icon-full-screen', click: this.scanCode },
                { text: '刷新页面', icon: 'el-icon-refresh', click: this.refreshPage },*/
                { text: '厂供料查找', icon: 'el-icon-search', click: this.openFactoryMaterialSearcher, show: true },
                { text: '查看下载清单', icon: 'el-icon-search', click: this.openDownloadList },
                { text: '查看本机工位', icon: 'el-icon-search', click: this.openBindOperator, show: true },
                { text: '系统配置', icon: 'el-icon-setting', click: this.openStationConfig },
                { text: '退出登录', icon: 'el-icon-switch-button', className: 'redBtn', click: this.outOfLogin },
            ]
        }
    },
    mounted() {
        if (this.curIdentity.identityName === '供应商' || this.curIdentity.identityName === '客户') {
            this.actions[0].show = false
            this.actions[1].show = false
            this.actions[3].show = false
        }

        this.MessageNoticeCount()
        this.KeepAlive()
    },
    methods: {
        /*
         * 功能：访问个人主页
         * Author：潘溢林
         */
        toPersonalPage() {
            this.$router.push({
                path: '/person/info'
            })
        },
        /*
         * 功能：切换账户
         * Author：潘溢林
         */
        outOfLogin() {
            /* 保存到Vuex */
            this.$store.dispatch('humanRemove')
        },
        /*
         * 功能：分享本页
         * Author：潘溢林
         */
        SharedPage() {
            errorNotification({ message: '测试中...' })
        },
        /*
         * 功能：扫一扫
         * Author：潘溢林
         */
        scanCode() {
            errorNotification({ message: '测试中...' })
        },
        /*
         * 功能：刷新页面
         * Author：潘溢林
         */
        refreshPage() {
            location.reload()
        },
        /*
         * 功能：切换角色
         * Author：潘溢林
         */
        toggleRole(identity) {
            this.$store.dispatch('toggleIdentity', identity)
        },
        /*
         * 功能：打开下载清单
         * Author：潘溢林
         */
        openDownloadList() {
            this.$store.getters.downloader.show = true
        },
        /*
         * 功能：厂供料查找器
         * Author：潘溢林
         */
        openFactoryMaterialSearcher() {
            this.$refs.FactoryMaterialSearcher.open()
        },

        /*
         * 功能：获取未读消息
         * 作者：潘溢林
         * 日期：23/05/18 15:32
         */
        MessageNoticeCount() {
            MessageNoticeCount({}).then(res => {
                this.messageTotal = res
                this.$forceUpdate()
                setTimeout(this.MessageNoticeCount, 10000)
            })
        },
        /*
         * 功能：保持生存时间
         * 作者：潘溢林
         * 日期：23/06/05 17:29
         */
        KeepAlive() {
            let operator = getCookie(CookieEnum.physicalOperator.code, true)

            if (operator) {
                PhysicalOperatorKeepAlive({
                    operatorMac: operator.operatorMac
                }).then(res => {
                    setTimeout(this.KeepAlive, 600000)
                })
            } else {
                setTimeout(this.KeepAlive, 600000)
            }
        },
        /*
         * 功能：打开消息弹窗
         * 作者：潘溢林
         * 日期：23/05/18 16:06
         */
        openMessageNoticeDialog() {
            this.$refs.MessageNoticeDialog.open()
        },
        /*
         * 功能：打开本机工位
         * 作者：潘溢林
         * 日期：23/06/08 17:12
         */
        openBindOperator() {
            this.$refs.BindOperatorDialog.open()
        },
        /**
         * 工位配置
         */
        openStationConfig() {
            var loginUserType = getCookie("curFc", true).userType;
            if (loginUserType.toLowerCase().includes("_admin")) {
                this.$refs.stationConfigDialogRef.open()
            } else {
                const _self = this;
                this.$prompt('请输入管理员手机', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^1\d{10}$/,
                    inputErrorMessage: '手机格式不正确',
                    beforeClose(action, instance, done) {
                        if (action === 'confirm') {
                            getUserInfoApi(instance.inputValue).then(rsp => {
                                if (rsp.factoryCode !== getCookie("curFc", true).factoryCode) {
                                    errorNotice("非本工厂手机号码");
                                    return;
                                }
                                if (rsp.userType.toLowerCase().includes("_admin")) {
                                    _self.$refs.stationConfigDialogRef.open();
                                    done();
                                } else {
                                    errorNotice("非管理员不允许操作");
                                }
                            })
                        } else {
                            done();
                        }
                    }
                })
            }

        }
    }
}
</script>

<style scoped>
.HeaderContainer {
    width: 100%;
    background-color: white;
    /* box-shadow: 0 1px 10px rgba(128, 128, 128, 0.69); */
    border-bottom: 1px solid #EBEEF5;
}

.HeaderToolsBox {
    float: left;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    text-align: center;
}

.HeaderImgBox {
    float: left;
    height: 60px;
    overflow: hidden;
}

.HeaderImgBox img {
    margin-top: 10px;
    margin-left: 10px;
}

.humanInfoBox {
    float: right;
}

.humanInfoHeader {
    /* width: 120px; */
    height: 60px;
    line-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    font-size: 14px;
    padding: 0 10px;
}

.highLight:hover {
    background-color: #eeb239;
    cursor: pointer;
}

.HeaderMenuBox {
    float: left;
    height: 60px;
    margin-top: -2px;
}

.humanIdentityBox {
    float: right;
}

.curIdentityBox {
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.HeaderMiniImgBox {
    float: left;
    margin-top: 10px;
}

.messageNoticeBox {
    float: right;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    border: 1px solid #EBEEF5;
    font-size: 20px;
    margin-top: 7px;
    margin-right: 10px;
}

.messageNoticeBox:hover {
    color: #f8cc67;
}
</style>
